<template>
    <div class="wizard-item">
        <div class="item-body mt-30" v-if="userData.stripe_customer_id !== null">
            <div>
                <span class="weight-500">{{ $translate('payment-method') }}</span>
            </div>
            
            <template v-if="paymentMethodsLoading">
                <div class="payment-element mt-15 processing">
                </div>
            </template>
            <template v-else>
                <div class="payment-element mt-15" :class="{ 'processing': card._isLoading }" v-for="(card, index) in cards" :key="index">
                    <template v-if="!card._isLoading">
                        <div class="payment-element-info flex-options">
                            
                            <div class="image card-icon"><img :src="cardIcons[card.card.brand]"></div>
                            <div class="text">
                                <span class="text-item">
                                    <span>{{ card.card.brand }} </span>•••• •••• •••• {{ card.card.last4 }}
                                </span>
                                <span class="text-item">
                                    <span>{{ $translate('expires') }}: </span> {{ card.card.exp_month | cardMonth}} / {{ card.card.exp_year | cardYear }}
                                </span>
                            </div>
                        </div>
                        <div class="payment-element-action flex-options">
                            <button type="button" class="btn btn-transp" @click="confirmDelete(card)">
                                <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M12.1213 7.87868C13.2929 9.05025 13.2929 10.9497 12.1213 12.1213C10.9497 13.2929 9.05025 13.2929 7.87868 12.1213C6.70711 10.9497 6.70711 9.05025 7.87868 7.87868C9.05025 6.70711 10.9497 6.70711 12.1213 7.87868" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.18 16.725V16.725C14.683 17.228 15.5 17.228 16.003 16.725L16.725 16.003C17.228 15.5 17.228 14.683 16.725 14.18V14.18C16.343 13.798 16.231 13.225 16.441 12.727C16.463 12.674 16.485 12.621 16.506 12.567C16.689 12.101 17.143 11.801 17.643 11.801H17.71C18.422 11.801 18.999 11.224 18.999 10.512V9.491C18.999 8.779 18.422 8.202 17.71 8.202H17.643C17.143 8.202 16.689 7.901 16.506 7.436C16.485 7.382 16.463 7.329 16.441 7.276C16.231 6.778 16.343 6.205 16.725 5.823V5.823C17.228 5.32 17.228 4.503 16.725 4L16.003 3.278C15.5 2.775 14.683 2.775 14.18 3.278V3.278C13.798 3.66 13.225 3.772 12.727 3.562C12.674 3.54 12.621 3.518 12.567 3.497C12.101 3.311 11.8 2.856 11.8 2.356V2.289C11.8 1.577 11.223 1 10.511 1H9.49C8.777 1 8.2 1.577 8.2 2.289V2.356C8.2 2.856 7.899 3.31 7.434 3.493C7.38 3.515 7.327 3.536 7.274 3.559C6.776 3.769 6.203 3.657 5.821 3.275V3.275C5.318 2.772 4.501 2.772 3.998 3.275L3.275 3.997C2.772 4.5 2.772 5.317 3.275 5.82V5.82C3.657 6.202 3.769 6.775 3.559 7.273C3.536 7.327 3.515 7.38 3.494 7.434C3.311 7.899 2.856 8.2 2.356 8.2H2.289C1.577 8.2 1 8.777 1 9.489V10.51C1 11.223 1.577 11.8 2.289 11.8H2.356C2.856 11.8 3.31 12.101 3.493 12.566C3.514 12.62 3.536 12.673 3.558 12.726C3.768 13.224 3.656 13.797 3.274 14.179V14.179C2.771 14.682 2.771 15.499 3.274 16.002L3.996 16.724C4.499 17.227 5.316 17.227 5.819 16.724V16.724C6.201 16.342 6.774 16.23 7.272 16.44C7.325 16.462 7.378 16.484 7.432 16.505C7.898 16.688 8.198 17.142 8.198 17.642V17.709C8.198 18.421 8.775 18.998 9.487 18.998H10.508C11.22 18.998 11.797 18.421 11.797 17.709V17.642C11.797 17.142 12.098 16.688 12.563 16.505C12.617 16.484 12.67 16.462 12.723 16.44C13.224 16.231 13.797 16.343 14.18 16.725V16.725Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg> -->
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5429 21.0037H8.4571C7.2809 21.0037 6.30301 20.0982 6.21279 18.9254L5.24719 6.37265H18.7528L17.7872 18.9254C17.697 20.0982 16.7191 21.0037 15.5429 21.0037V21.0037Z" stroke="#E12525" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20.0034 6.37264H3.9967" stroke="#E12525" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18638 2.99625H14.8137C15.4353 2.99625 15.9392 3.50014 15.9392 4.12172V6.37265H8.06091V4.12172C8.06091 3.50014 8.5648 2.99625 9.18638 2.99625Z" stroke="#E12525" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.9696 10.8745V16.5019" stroke="#E12525" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.0305 10.8745V16.5019" stroke="#E12525" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </template>
                </div>
            </template>

            <button type="button" class="btn btn-primary btn-sm mt-10" @click="showCardActions = true;">{{ $translate('add-payment-method') }}</button>
        </div>
        <div class="item-body mt-30" :class="{ 'border-top': userData.stripe_customer_id !== null }">
            <span class="weight-500">{{ $translate('your-plan') }}</span>
            <div class="plan-content flex-sm-col">
                <div class="plan-content-item">
                    <div class="notifications-item none-hover flex">
                        <div class="label-icon blue">
                            <img src="@/assets/img/star-icon.svg">
                        </div>
                        <div class="notifications-item__text flex-auto">
                            <h6>{{ currentPlan }} {{ $translate('plan') }}</h6>
                            <span>{{ $translate('see-plan-details') }}</span>
                        </div>
                    </div>
                    <div class="marker-list flex flex-xs-col">
                        <ul v-for="(featureCol, featureColIndex) in featuresPlans" :key="featureColIndex">
                            <li v-for="(featureItem, featureItemIndex) in featureCol" :key="featureItemIndex">
                                <span>{{ featureItem }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="plan-content-item">
                    <button type="button" class="btn btn-primary btn-sm" @click="upgradePlan('Do you need additional features to your plan?')">{{ $translate('upgrade-plan') }}</button>
                    <button type="button" class="btn btn-w-shadow btn-sm" @click="upgradePlan('Do you need cancel your plan?')">{{ $translate('cancel-plan') }}</button>
                </div>
            </div>
        </div>
        <div class="item-body">
            <span class="weight-500">{{ $translate('billing-cycles') }}</span>
            <div class="delete-account mt-15">
                <div class="group-delete">
                    <!-- <h3>Monthly</h3>
                    <p>Next payment: US $129.00 on September  4, 2021</p> -->
                    <template v-if="stripeData && stripeData.stripe_subscription">
                        <h3 class="flex items-center">Yearly 
                            <span class="subscription_status_badge" :class="{'active':stripeData.stripe_subscription.status === 'active'}">
                                {{ stripeData.stripe_subscription.status }}
                            </span>
                        </h3> 
                        <p>{{ $translate('next-payment') }}: US ${{ stripeData.stripe_subscription.plan.amount / 100 }} on {{ getNowDate(stripeData.stripe_subscription.current_period_end*1000, true) }}</p>
                    </template>
                    <h3 style="color: #9DB0B7;" v-else>N/A</h3>
                </div>
                <div class="group-delete">
                    <button type="button" class="btn btn-w-shadow btn-standart-2">{{ $translate('switch-billing-cycle') }}</button>
                </div>
            </div>
        </div>

        <CardActions
            v-if="showCardActions"
            @updateList="getUserPaymentMethods"
            @close="showCardActions = false; editedCard = false;"
        />
    </div>
</template>

<script>
import { billingPlans } from "@/config/index.js";
import CardActions from '../../components/popups/CardActions.vue';
import { API } from 'aws-amplify';

export default {
    name: 'PlanAndBilling',
    components: {
        CardActions
    },
    filters: {
        cardMonth(x) {
            x = x.toString();

            return x.length == 2 ? x : `0${x}`;
        },
        cardYear(x) {
            return x.toString().substr(2, 4);
        }
    },
    data(){
        return {
            plans: billingPlans,
            showCardActions: false,
            paymentMethodsLoading: false,
            cards: [],
            cardIcons: {
                visa: 'img/visa-icon.svg',
                mastercard: 'img/mastercard-icon.svg',
                amex: 'img/amex-icon.svg',
                discover: 'img/discover-icon.svg',
                diners: 'img/diners-icon.svg',
                jcb: 'img/jcb-icon.svg',
                unknown: 'img/unknown-icon.png'
            },
            stripeData: null,
        }
    },
    computed: {
		userData() {
			return this.$store.state.userData || {}
		},
        authData() {
			return this.$store.state.authData || {}
		},
        currentPlan(){
            return this.userData.grants === null || this.userData.grants === 'BASIC' ? 'BASIC' : this.userData.grants;
        },
        featuresPlans(){
            let array = Object.entries(this.plans[this.currentPlan].features).map(item => item[1]).flat(), result = [], length = 3;

            switch (this.currentPlan) {
                case "BASIC":
                    length = 3; break;
                case "PRO":
                    length = 5; break;
                case "GOLD":
                    length = 6; break;
                case "PLATINUM":
                    length = 6; break;
                default:
                    length = 3; break;
            }

            while(array.length) result.push(array.splice(0, length));
            return result;
        },
	},
    methods: {
        async upgradePlan(titleValue){
            const options = { title: titleValue, size: "sm", okLabel: "Contact Enismaro", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) window.open('mailto:info@enismaro.com');
            });
        },
        openCardActions() {
            this.showCardActions = true;
        },
        async getUserPaymentMethods() {
            if(this.userData.stripe_customer_id){
                this.paymentMethodsLoading = true;
            
                const response = await API.post('stripeapi', '/stripe/payment-methods-list', {
                    body: {
                        options: {
                            customer: this.userData.stripe_customer_id,
                            type: 'card'
                        }
                    }
                });
                this.cards = response.data;

                this.stripeData = await API.post('stripeapi', '/stripe/read-plan', { body: {
                        stripe_customer_id: this.userData.stripe_customer_id
                    }
                });
                console.log('stripeData:', this.stripeData);

                this.paymentMethodsLoading = false;
            }
        },
        async deleteCard(id) {
            const response = await API.post('stripeapi', `/stripe/customer-delete-source`, {
                body: {
                    cusId: this.$store.state.userData['stripe_customer_id'],
                    cardID: id,
                }
            });
            await this.getUserPaymentMethods();

            if (!this.cards.length) {
                this.openCardActions();
            }
        },
        async confirmDelete(card) {
            const options = { title: `Delete •••• •••• •••• ${card.card.last4} card?`, size: "sm", okLabel: "Delete", cancelLabel: "Cancel" };

            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    this.deleteCard(card.id);
                }
            });
        },
        checkNeedPlus(numb){
            if(+numb<10){
                return `0${numb}`;
            } else {
                return numb;
            }
        },
        getNowDate(date, forMarkupDate = false){
            let dateNow = date ? new Date(date) : new Date;
            let returnedDate = `${dateNow.getFullYear()}-${this.checkNeedPlus(dateNow.getMonth()+1)}-${this.checkNeedPlus(dateNow.getDate())}T${this.checkNeedPlus(dateNow.getHours())}:${this.checkNeedPlus(dateNow.getMinutes())}`;
            
            if(forMarkupDate){
                return `${dateNow.getFullYear()}-${this.checkNeedPlus(dateNow.getMonth()+1)}-${this.checkNeedPlus(dateNow.getDate())}, ${this.checkNeedPlus(dateNow.getHours())}:${this.checkNeedPlus(dateNow.getMinutes())}`;
            }

            return returnedDate;
        },
    },
    created() {
        this.getUserPaymentMethods();
    }
}
</script>
<style scoped>
.payment-element.processing {
    min-height: 62px;
}

.card-icon {
    max-width: 40px;
    max-height: 24px;
    width: 100%;
    height: auto;
    object-fit: contain;
    vertical-align: middle;
}
.subscription_status_badge {
    margin-left: 5px;
    border-radius: 5px;
    display: flex;
    align-self: center;
    background: #efefef;
    padding: 1px 3px;
}
.subscription_status_badge.active {
    background: #23d131;
    color: #fff;
}
</style>