<template>
    <div class="wizard-item">
        <div class="item-body">
            <div class="ulpload-photo">
                <span class="weight-500">{{ $translate('profile-photo') }}</span>
                <div class="flex">
                    <div class="profile-photo" v-if="!uploadUserImageLoading">
                        <img v-if="uploadedImage || profile_photo && !imageRemoved" :src="uploadedImage || profile_photo">
                        <img v-else src="@/assets/img/user.png">
                    </div>
                    <div class="profile-photo processing" v-if="uploadUserImageLoading">
                        <img src="@/assets/img/user.png">
                    </div>
                    <div class="action-apload flex" style="margin: 0;">
                        <label class="upload btn btn-primary btn-sm next-btn">
                            <input type="file" accept="image/jpeg,image/png" @change="uploadUserImage">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 10V10C19 14.971 14.971 19 10 19V19C5.029 19 1 14.971 1 10V10C1 5.029 5.029 1 10 1V1C14.971 1 19 5.029 19 10Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M10 6V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M7 9L10 6L13 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <span>{{ $translate('upload') }}</span>
                        </label>
                        <button v-if="profile_photo && !imageRemoved" class="btn btn-w-shadow btn-standart-2" @click="removeUserImage">
                            {{ $translate('remove') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="items-group cards border-top mt-15">
            <div class="cards__col-6">
                <div class="el-form mt-15">
                    <label class="el-form__title">{{ $translate('full-name') }}</label>
                    <input type="text" name="fullname" class="input" v-model="user.fullname" @change="updateUserData">
                </div>
            </div>
            <div class="cards__col-6">
                <div class="el-form mt-15">
                    <label class="el-form__title">{{ $translate('email') }}</label>
                    <input type="text" class="input" disabled :value="authData.attributes.email">
                </div>
            </div>
        </div>
        <div class="items-group cards border-top mt-15">
            <div class="cards__col-6">
                <div class="el-form mt-15 pass-change">
                    <label class="el-form__title">
                        <span>{{ $translate('password') }}</span>
                        <a data-target="change-password" class="btn" @click="passwordPopup = true">{{ $translate('change-password') }}</a>
                    </label>
                    <input type="password" class="input" :value="pl" disabled>
                </div>
            </div>
        </div>
        <div class="items-group cards border-top mt-15">
            <div class="cards__col-12">
                <div class="delete-account mt-15">
                    <div class="group-delete">
                        <h3>{{ $translate('delete-account') }}</h3>
                        <p>{{ $translate('by-deleting-your-account-you-will-lose-all-your-data') }}</p>
                    </div>
                    <div class="group-delete">
                        <button type="submit" class="btn btn-w-shadow btn-standart-2 red-color" @click="userDeletePopup = true">{{ $translate('delete-account') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination wizard-actions fixed" v-show="dataChanged">
			<div class="pagination__body">
				<!-- <button type="button" class="btn btn-w-shadow btn-standart-2">Cancel</button>  -->
				<div class="pagination_right_btn_group">
					<button type="button" class="btn btn-primary btn-standart" @click="dataSave">
						{{ $translate('save') }}
					</button> 
				</div>
			</div>
		</div>

		<div class="popup_wrpr danger-message" v-if="userDeletePopup">
			<div class="popup_overlay" @click="userDeletePopupClose"></div>
			<div class="popup__window">
				<div class="popup__window-content">
					<div class="popup__window-header">
						<h2>{{ $translate('delete-account') }}</h2>
						<button type="button" class="popup__closer" @click="userDeletePopupClose">
							<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
						</button>
					</div>
					<div class="popup__window-body">
						<p class="popup__message">
							{{ $translate('do-you-confirm-delete-for-your-account?') }}
						</p>
					</div>
					<div class="popup__window-actions">
						<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="userDeletePopupClose">
							<span>{{ $translate('cancel') }}</span>
						</button>
						<button type="button" class="btn btn-primary btn-standart-2" @click="userDelete">
							<span>{{ $translate('confirm') }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>

        <div class="popup_wrpr" data-target="change-password" v-if="passwordPopup">
			<div class="popup_overlay"></div>
			<div class="popup__window">
				<div class="popup__window-content w-450">
					<div class="popup__window-header">
						<h2>{{ $translate('change-password') }}</h2>
						<button type="button" class="popup__closer" @click="passwordPopup = false">
							<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
						</button>
					</div>
					<div class="popup__window-body">
						<div class="items-group cards">
							<div class="cards__col-12">
								<div class="el-form mt-15">
									<label class="el-form__title">{{ $translate('old-password') }}</label>
									<input type="password" class="input" v-model="oldPassword">
								</div>
								<div class="el-form mt-15">
									<label class="el-form__title">{{ $translate('new-password') }}</label>
									<input type="password" class="input" v-model="newPassword">
								</div>
								<div class="el-form mt-15">
									<label class="el-form__title">{{ $translate('repeat-new-password') }}</label>
									<input type="password" class="input" v-model="comfirmNewPassword">
								</div>
							</div>
						</div>
						<div class="popup__window-actions">
							<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="passwordPopup = false">
								<span>{{ $translate('cancel') }}</span>
							</button>
							<button type="button" class="btn btn-primary btn-standart-2" @click="confirmPasswordChange">
								<span>{{ $translate('confirm') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<transition name="changes-saved-info-show">
            <div class="pagination wizard-actions fixed changes_saved_info" v-show="changesSaved">
                <div class="pagination__body">
                    <img src="@/assets/img/upload-image-icon.svg">
                    {{ $translate('settings-succesfully-updated') }}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { API, Storage, Auth } from 'aws-amplify';
import { 
	updateUser, 
} from '@/graphql/mutations';
import Base from '@/components/base';

export default {
    components: {
		...Base,
	},
    data() {
		return {
			user: {
				fullname: '',
			},

            passwordPopup: false,
			oldPassword: '',
			newPassword: '',
			comfirmNewPassword: '',

            userDeletePopup: false,
			dataChanged: false,
			input: {},
			imageRemoved: false,
			uploadedImage: null,
			changesSaved: false,
			uploadUserImageLoading: false,
		}
	},
	computed: {
		userData() {
			return this.$store.state.userData || {}
		},
        authData() {
			return this.$store.state.authData || {}
		},
		profile_photo() {
			return this.$store.state.profile_photo
		},
        pl() {
			let result = '';
			let pl = this.authData.attributes['custom:pl'];
			for(let i = 0; i < pl; i++) {
				result+='*'
			}
			return result
		}
	},
    methods: {
        getUserData() {
			this.user = {
				...this.user,
				...this.userData
			}
			if(!this.user.fullname || !this.user.fullname.length) this.user.fullname = this.authData.attributes['custom:full_name'];
		},
		async dataSave(){
			await API.graphql({
				query: updateUser,
				variables: { 
					id: this.userData.id, 
					input: {
						id: this.userData.id,
						...this.input
					}
				}
			});

			this.dataChanged = false;
			this.input = {};

			this.changesSaved = true;
            setTimeout(() => {
               this.changesSaved = false; 
            }, 2000);
		},
		async updateUserData(ev) {
			const target = ev.target;
			const value = target.value;
			const name = target.name;

			this.input = {
				...this.input,
				[name]: value
			};

			this.dataChanged = true;
		},
		async removeUserImage() {
			// const input = {
			// 	id: this.userData.id,
			// 	profile_photo: null
			// }

			// await API.graphql({
			// 	query: updateUser,
			// 	variables: { 
			// 		id: this.userData.id, 
			// 		input 
			// 	}
			// })
			this.input = {
				...this.input,
				profile_photo: null
			};
			this.dataChanged = true;
			this.imageRemoved = true;
		},
		async uploadUserImage(ev) {
			this.uploadUserImageLoading = true;
			const file = ev.target.files[0];
			
			this.uploadedImage = URL.createObjectURL(ev.target.files[0])

			if(!file) return

			const { name } = file;
			let s3Name=this.userData.id+name
			const s3File = await Storage.put(s3Name, file);

			// const input = {
			// 	id: this.userData.id,
			// 	profile_photo: s3File.key
			// }

			// await API.graphql({
			// 	query: updateUser,
			// 	variables: { 
			// 		id: this.userData.id, 
			// 		input 
			// 	}
			// })
	
			this.input = {
				...this.input,
				profile_photo: s3File.key
			};
			this.dataChanged = true;
			this.imageRemoved = false;
			this.uploadUserImageLoading = false;
		},
        async confirmPasswordChange() {
			const user = await Auth.currentAuthenticatedUser();
			
			let value = { show: true }
			this.passwordPopup = false;
			if(
				!this.comfirmNewPassword.length ||
				!this.newPassword.length ||
				!this.oldPassword.length
			) {
				value.message = 'Please fill all fields';
				value.status = 'ERROR';
				this.$store.commit('SET_UI', { field: 'notificationPopup', value })
				return
			}
			if(this.comfirmNewPassword !== this.newPassword) {
				value.message = 'Passwords don\'t match';
				value.status = 'ERROR';
				this.$store.commit('SET_UI', { field: 'notificationPopup', value })
				return
			}
			
			try {
				const data = await Auth.changePassword(user, this.oldPassword, this.newPassword);
				value.message = 'Your password changed successfully';
				value.status = data;

				await Auth.updateUserAttributes(user, {
					'custom:pl': ''+this.newPassword.length
				});
				this.$store.commit('SET_AUTH_ATTR', { field: 'custom:pl', value: this.newPassword.length })
			} catch(err) {
				value.message = err.message;
				value.status = 'ERROR';
			} finally {
				this.$store.commit('SET_UI', { field: 'notificationPopup', value })
			}
		},
        userDeletePopupClose() {
			this.userDeletePopup = false;
		},
		async userDelete() {
			await Auth.user.deleteUser(() => {
				Auth.signOut();
			});
		},
    },
    async mounted() {
		await this.getUserData();
		this.input = {};
	},
}
</script>