<template>
    <div class="wizard-item">
        <div class="item-body border-top mt-15" :class="{ 'has-items': company_photo.length }">
            <span class="weight-500 w-100">{{ $translate('company-photo') }}</span>
            <div class="gallery" style="grid-template-columns: repeat(1, 1fr);width: initial;">
				<template v-if="newCompanyPhoto.length">
					<div class="gallery__item" v-for="(newPhoto, newPhotoIndex) in newCompanyPhoto" :key="newPhotoIndex">
						<img :src="getImageSrcFromInputFile(newPhoto)">
						<button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="newCompanyPhoto = []">
							<inline-svg :src="require(`@/assets/img/x.svg`)"/>
						</button>
					</div>
				</template>
                <div v-else class="gallery__item" v-for="photo in company_photo" :key="photo.id">
                    <!-- @click="openGallery(i)" -->
					<img :src="photo.url">
                    <button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="deleteMediaPopupOpen(photo, 'company_photo')">
                        <inline-svg :src="require(`@/assets/img/x.svg`)"/>
                    </button>
                </div>
            </div>
            <div 
                class="fake-dropzone" 
                :class="{ 'drop-ready': dragState }" 
                @drop.prevent.stop="dropCompanyPhoto"  
                @dragenter.prevent @dragover.prevent
            >
                <label>
                    <img src="@/assets/img/upload-image-icon.svg">
                    <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file"') }}</span>
                    <input type="file" accept="image/*" @change="uploadCompanyPhoto" multiple>
                </label>
            </div>
        </div>
        <div class="item-body">
            <span class="weight-500">{{ $translate('basic-info') }}</span>
            <div class="items-group cards">
                <div class="cards__col-4">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('company-name') }}</label>
                        <input type="text" name="company_name" class="input" v-model="company.company_name" @input="updateCompanyData">
                    </div>
                </div>
                <div class="cards__col-4">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('year-of-foundation') }}</label>
                        <input type="text" name="year_of_foundation" class="input" v-model="company.year_of_foundation" @input="updateCompanyData">
                    </div>
                </div>
                <div class="cards__col-4">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('website') }}</label>
                        <input type="text" name="website" class="input" v-model="company.website" @input="updateCompanyData">
                    </div>
                </div>
            </div>
            <div class="items-group cards">
                <div class="cards__col-12">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('history-of-the-company') }}</label>
                        <textarea class="textarea h-140" name="history_of_company" v-model="company.history_of_company" @change="updateCompanyData"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="item-body mt-30 border-top">
            <span class="weight-500">{{ $translate('product-&-farm-info') }}</span>
            <div class="items-group cards">
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('production-/-year') }}</label>
                        <input type="text" class="input" name="production_per_year" v-model="company.production_per_year" @change="updateCompanyData" @input="dataChanged = true">
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('production-area') }}</label>
                        <input type="text" class="input" name="production_area" v-model="company.production_area" @change="updateCompanyData" @input="dataChanged = true">
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('soils') }}</label>
                        <textarea class="textarea h-65" name="soils" v-model="company.soils" @change="updateCompanyData" @input="dataChanged = true"></textarea>
                    </div>
                </div>
            </div>
            <!-- <div class="items-group cards border-top mt-15">
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('cellar-work') }}</label>
                        <textarea class="textarea h-65" name="cellar_work" v-model="company.cellar_work" @change="updateCompanyData" @input="dataChanged = true"></textarea>
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('productivity') }}</label>
                        <textarea class="textarea h-65" name="productivity" v-model="company.productivity" @change="updateCompanyData" @input="dataChanged = true"></textarea>
                    </div>
                </div>
            </div> -->
            <div class="items-group cards border-top mt-15">
                <div class="cards__col-12">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('philosophy') }}</label>
                        <textarea class="textarea" name="philosophy" v-model="company.philosophy" @change="updateCompanyData" @input="dataChanged = true"></textarea>
                    </div>
                </div>
            </div>
            <div class="items-group cards border-top mt-15">
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('vat-number') }}</label>
                        <input type="text" class="input" name="vat" v-model="company.vat" @change="updateCompanyData" @input="dataChanged = true">
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('fiscal-code') }}</label>
                        <input type="text" class="input" name="fiscal" v-model="company.fiscal" @change="updateCompanyData" @input="dataChanged = true">
                    </div>
                </div>
            </div>
        </div>
		<div class="item-body border-top mt-30">
            <div class="flex flex-col">
                <div class="card flex-auto" style="margin-bottom: 0px;">
                    <div class="card__header flex items-center style-2">
                        <span class="flex-auto">{{ $translate('location') }}</span>
                    </div>
                    <div class="map search-map" style="border-bottom-left-radius: var(--border-radius-7);border-bottom-right-radius: var(--border-radius-7); overflow: hidden;">
                        <!-- <input type="text" style="z-index:2"
                            placeholder="USA, New York, New York" 
                            class="input bg-left search-icon"
                            name="location"
                            v-model="company.location"
                            @change="updateCompanyData"
                        > -->
						<div class="input-with-suggestions-wrpr">
							<a href="#" style="position: absolute;left: 7px;top: 5px;width: 30px;height: 30px;" @click.prevent="updateCompanyData"></a>
							<input type="text" style="z-index:2"
								placeholder="USA, New York, New York" 
								class="input bg-left search-icon"
								name="location"
								v-model="company.location"
								@change="updateCompanyData"
							>
							<ul v-if="showSuggestions">
								<li v-for="(locationItem, locationItemIndex) in suggestionsLocations" :key="locationItem.label+'-'+locationItemIndex">
									<a href="#" @click.prevent="setLocation({ lat: locationItem.latitude, lng: locationItem.longitude }, true)">
										{{ locationItem.label }}
									</a>
								</li>
							</ul>
						</div>
                        <div id="company-map" style="width: 100%; height: 410px"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item-body border-top mt-15">
            <span class="weight-500">{{ $translate('location') }}</span>
            <div class="items-group cards">
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('country') }}</label>
                        <Dropdown 
                            :options="countries"
                            :current="company.country"
                            name="country"
							:search="true"
                            @change="updateCompanyData"
                        />
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('city') }}</label>
                        <input type="text" class="input" name="city" v-model="company.city" @change="updateCompanyData" @input="dataChanged = true">
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">{{ $translate('province') }}</label>
                        <input type="text" class="input" name="province" v-model="company.province" @change="updateCompanyData"	@input="dataChanged = true">
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15">
                        <label class="el-form__title">Address</label>
                        <input
							type="text" class="input" name="address"
							v-model="company.lat_lng.address"
							@input="(e)=>{
								dataChanged = true;
								input = {
									...input,
									lat_lng: {
										...company.lat_lng,
										address: e.target.value
									}
								};
							}"
						>
                    </div>
                </div>
            </div>
        </div>


        <!--   Certifications    -->
        <div class="item-body border-top mt-15" :class="{ 'has-items': certificates.length }">
            <span class="weight-500 w-100">{{ $translate('certifications') }}</span>
            <ul class="editable-list">
                <li class="editable-list__item" v-for="m in certificates" :key="m.id">
                    <div class="data-row">
                        <ul class="data-row__list attachment-list">
                            <li class="data-row__item attachment-list-title">
                                <inline-svg :src="require(`@/assets/img/document-file-table.svg`)"/>
                                <span style="margin-left: 10px">{{ m.title }}</span>
                            </li>
                            <li class="data-row__item">
                                <a target="_blank" :href="m.url" class="btn btn-primary btn-sm">{{ $translate('download') }}</a>
                            </li>
                            <li>
                                <button class="btn btn-danger btn-sm" @click.prevent="deleteMediaPopupOpen(m, 'certificate')">
                                    <inline-svg :src="require(`@/assets/img/trash-delete.svg`)"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="editable-list__item" v-for="(m, mIndex) in newCertificates" :key="mIndex">
                    <div class="data-row">
                        <ul class="data-row__list attachment-list">
                            <li class="data-row__item attachment-list-title">
                                <inline-svg :src="require(`@/assets/img/document-file-table.svg`)"/>
                                <span style="margin-left: 10px">{{ m.name }}</span>
                            </li>
                            <li>
                                <button class="btn btn-danger btn-sm" @click.prevent="newCertificatesDelete(mIndex)">
                                    <inline-svg :src="require(`@/assets/img/trash-delete.svg`)"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <div 
                class="fake-dropzone" 
                :class="{ 'drop-ready': dragState }" 
                @drop.prevent.stop="dropCertificate"  
                @dragenter.prevent @dragover.prevent
            >
                <label>
                    <img src="@/assets/img/upload-image-icon.svg">
                    <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                    <input type="file" @change="uploadCertificate" multiple>
                </label>
            </div>
        </div>

        <!--   Documentation   -->
        <div class="item-body border-top mt-15" :class="{ 'has-items': documents.length }">
            <span class="weight-500 w-100">{{ $translate('documentation') }}</span>
            <ul class="editable-list">
                <li class="editable-list__item" v-for="m in documents" :key="m.id">
                    <div class="data-row">
                        <ul class="data-row__list attachment-list">
                            <li class="data-row__item attachment-list-title">
                                <inline-svg :src="require(`@/assets/img/Document.svg`)"/>
                                <span style="margin-left: 10px">{{ m.title }}</span>
                            </li>
                            <li class="data-row__item">
                                <a target="_blank" :href="m.url" class="btn btn-primary btn-sm">{{ $translate('download') }}</a>
                            </li>
                            <li>
                                <button class="btn btn-danger btn-sm" @click.prevent="deleteMediaPopupOpen(m, 'document')">
                                    <inline-svg :src="require(`@/assets/img/trash-delete.svg`)"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="editable-list__item" v-for="(m, index) in newDocuments" :key="index">
                    <div class="data-row">
                        <ul class="data-row__list attachment-list">
                            <li class="data-row__item attachment-list-title">
                                <inline-svg :src="require(`@/assets/img/Document.svg`)"/>
                                <span style="margin-left: 10px">{{ m.name }}</span>
                            </li>
                            <li>
                                <button class="btn btn-danger btn-sm" @click.prevent="newDocumentsDelete(index)">
                                    <inline-svg :src="require(`@/assets/img/trash-delete.svg`)"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <div 
                class="fake-dropzone" 
                :class="{ 'drop-ready': dragState }" 
                @drop.prevent.stop="dropDocument"  
                @dragenter.prevent @dragover.prevent
            >
                <label>
                    <img src="@/assets/img/upload-image-icon.svg">
                    <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                    <input type="file" @change="uploadDocument" multiple>
                </label>
            </div>
        </div>

        <!--   Media   -->
        <div class="item-body border-top mt-15" :class="{ 'has-items': media.length }">
            <span class="weight-500 w-100">{{ $translate('media') }}</span>
            <div class="gallery">
                <div class="gallery__item" v-for="(m, i) in mediaImages" :key="m.id">
                    <img :src="m.url" @click="openGallery(i)">
                    <button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="deleteMediaPopupOpen(m, 'media')">
                        <svg fill="none" viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
							<path xmlns="http://www.w3.org/2000/svg" d="M15 5L5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							<path xmlns="http://www.w3.org/2000/svg" d="M5 5L15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
                    </button>
                </div>
                <div class="gallery__item" v-for="m in mediaVideos" :key="m.id">
					<!-- poster="video/duel.jpg" -->
					<video width="100%" height="100%" controls="controls" style="background: #e4e4e4;" :class="'video-'+m.id">
   						<source :src="m.url">
					</video>
                    <button class="delete-gallery__item btn" style="left: 10px;right: auto;" @click="videoFullscreen('video-'+m.id)">
                        <inline-svg :src="require(`@/assets/img/Full-Screen.svg`)"/>
                    </button>
                    <button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="deleteMediaPopupOpen(m, 'media')">
                        <svg fill="none" viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
							<path xmlns="http://www.w3.org/2000/svg" d="M15 5L5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							<path xmlns="http://www.w3.org/2000/svg" d="M5 5L15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
                    </button>
                </div>
                <div class="gallery__item" v-for="(m, index) in newMedia" :key="index">
					<template v-if="m.type.includes('video')">
						<video width="100%" height="100%" controls="controls" style="background: #e4e4e4;" :class="'video-local-'+index">
							<source :src="getImageSrcFromInputFile(m)">
						</video>
						<button class="delete-gallery__item btn" style="left: 10px;right: auto;" @click="videoFullscreen('video-local-'+index)">
							<inline-svg :src="require(`@/assets/img/Full-Screen.svg`)"/>
						</button>
					</template>
					<img v-else-if="m.type.includes('image')" :src="getImageSrcFromInputFile(m)">
                    
                    <button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="newMediaDelete(index)">
                        <svg fill="none" viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
							<path xmlns="http://www.w3.org/2000/svg" d="M15 5L5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							<path xmlns="http://www.w3.org/2000/svg" d="M5 5L15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
                    </button>
                </div>
            </div>
            <div 
                class="fake-dropzone" 
                :class="{ 'drop-ready': dragState }" 
                @drop.prevent.stop="dropMedia"  
                @dragenter.prevent @dragover.prevent
            >
                <label>
                    <img src="@/assets/img/upload-image-icon.svg">
                    <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                    <input type="file" accept=".jpg, .jpeg, .png, .svg, .mp4, .avi, .wmv, .webm" @change="uploadMedia" multiple>
                </label>
            </div>
        </div>

        <div class="popup_wrpr danger-message" v-if="mediaPopup">
			<div class="popup_overlay" @click="deleteMediaPopupClose"></div>
			<div class="popup__window">
				<div class="popup__window-content">
					<div class="popup__window-header">
						<h2>{{ $translate('delete') }} {{ deleteMediaType }}</h2>
						<button type="button" class="popup__closer" @click="deleteMediaPopupClose">
							<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
						</button>
					</div>
					<div class="popup__window-body">
						<p class="popup__message">
							{{ $translate('do-you-confirm-delete-file?') }}
						</p>
					</div>
					<div class="popup__window-actions">
						<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="deleteMediaPopupClose">
							<span>{{ $translate('cancel') }}</span>
						</button>
						<button type="button" class="btn btn-primary btn-standart-2" @click="deleteMediaFile">
							<span>{{ $translate('confirm') }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<Gallery v-if="galleryShow" :media="mediaImages" :currentImage="currentImage" @close="closeGallery"  />

        <div class="pagination wizard-actions fixed" v-show="dataChanged">
			<div class="pagination__body">
				<!-- <button type="button" class="btn btn-w-shadow btn-standart-2">Cancel</button>  -->
				<div class="pagination_right_btn_group">
					<button type="button" class="btn btn-primary btn-standart" @click="dataSave">
						{{ $translate('save') }}
					</button> 
				</div>
			</div>
		</div>

        <transition name="changes-saved-info-show">
            <div class="pagination wizard-actions fixed changes_saved_info" v-show="changesSaved">
                <div class="pagination__body">
                    <img src="@/assets/img/upload-image-icon.svg">
                    {{ $translate('settings-succesfully-updated') }}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { API, Storage } from 'aws-amplify';
import { 
	updateCompany,
	createMedia,
	deleteMedia,

	createDocument,
	deleteDocument,
	updateDocument,
	
	createCertificate,
	deleteCertificate,
	updateCertificate
} from '@/graphql/mutations';

import Base from '@/components/base';
import countries from '@/assets/json/countries.js';

export default {
    components: {
		...Base,
	},
    data(){
        return {
            company: {},
			company_photo: [],
			media: [],
			certificates: [],
			documents: [],
			deleteMediaTarget: null,
			deleteMediaType: 'media',
			mediaPopup: false,
			dragState: false,


			galleryShow: false,
			currentImage: 0,

            dataChanged: false,
            input: {},
            changesSaved: false,
			countries: countries,
			mediaFormats: {
				images: ['.jpg', '.jpeg', '.png', '.svg'],
				videos: ['.mp4', '.avi', '.wmv', '.webm'],
			},

			newCompanyPhoto: [],
			newCertificates: [],
			newDocuments: [],
			newMedia: [],

			suggestionsLocations: [],
			showSuggestions: false,
        }
    },
    computed: {
		companyData() {
			return this.$store.state.companyData || {}
		},
		mediaImages(){
			let returnedImages = [];
			this.media.forEach(item => {
				this.mediaFormats.images.forEach(item2 => {
					if(item.key.includes(item2)){
						returnedImages.push(item);
					}
				})
			});
			return returnedImages;
		},
		mediaVideos(){
			let returnedVideos = [];
			this.media.forEach(item => {
				this.mediaFormats.videos.forEach(item2 => {
					if(item.key.includes(item2)){
						returnedVideos.push(item);
					}
				})
			});
			return returnedVideos;
		}
	},
	methods: {
		getImageSrcFromInputFile(value){
			return URL.createObjectURL(value);
		},
		videoFullscreen(refName){
			// let elem = this.$refs[refName];
			let elem = document.querySelector(`.${refName}`);
			console.log('elem:', elem);
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) { /* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE11 */
				elem.msRequestFullscreen();
			}
		},
        async dataSave(){
            await API.graphql({
				query: updateCompany,
				variables: { 
					id: this.companyData.id, 
					input: this.input
				}
			})


            this.input = {
                id: this.companyData.id
            };

			if(this.newCompanyPhoto?.length){
				let forCompanyDataNewPhoto = [];

				Object.values(this.newCompanyPhoto).forEach(async file => {
					let { name } = file;
					let s3File = await Storage.put(`company_photo/${name}`, file);
					let input = {
						companyMediaId: this.companyData.id,
						key: s3File.key
					}
					let newMedia = await API.graphql({
						query: createMedia,
						variables: { 
							input 
						}
					})
					let url = await Storage.get(s3File.key);
					// this.company_photo.push({
					// 	key: s3File.key,
					// 	id: newMedia.data.createMedia.id,
					// 	url
					// })
					this.company_photo = [{
						key: s3File.key,
						id: newMedia.data.createMedia.id,
						url
					}];

					forCompanyDataNewPhoto.push({
						company: this.companyData.id,
						key: s3File.key,
					})
				})

				setTimeout(async () => {
					await API.graphql({
						query: updateCompany,
						variables: { 
							id: this.companyData.id, 
							input: {
								id: this.companyData.id,
								company_photo: forCompanyDataNewPhoto
							}
						}
					});

					this.newCompanyPhoto = [];
				}, 2000);
			}

			if(this.newCertificates?.length){
				Object.values(this.newCertificates).forEach(async file => {
					let { name } = file;
					let s3File = await Storage.put(`certificates/${name}`, file);
					let input = {
						companyCertificationsId: this.companyData.id,
						key: s3File.key,
						title: name
					}
					let newCertificate = await API.graphql({
						query: createCertificate,
						variables: { 
							input 
						}
					})
					let url = await Storage.get(s3File.key);
					this.certificates.push({
						key: s3File.key,
						id: newCertificate.data.createCertificate.id,
						url,
						title: newCertificate.data.createCertificate.title
					})
				});

				this.newCertificates = [];
			}

			if(this.newDocuments?.length){
				Object.values(this.newDocuments).forEach(async file => {
					let { name } = file;
					let s3File = await Storage.put(`document/${name}`, file);
					let input = {
						companyDocumentationId: this.companyData.id,
						key: s3File.key,
						title: name
					}
					let newDocument = await API.graphql({
						query: createDocument,
						variables: { 
							input 
						}
					})
					let url = await Storage.get(s3File.key);
					this.documents.push({
						key: s3File.key,
						id: newDocument.data.createDocument.id,
						url,
						title: newDocument.data.createDocument.title
					})
				});

				this.newDocuments = [];
			}

			if(this.newMedia?.length){
				Object.values(this.newMedia).forEach(async file => {
					let { name } = file;
					let s3File = await Storage.put(`media/${name}`, file);
					let input = {
						companyMediaId: this.companyData.id,
						key: s3File.key
					}
					let newMedia = await API.graphql({
						query: createMedia,
						variables: { 
							input 
						}
					})
					let url = await Storage.get(s3File.key);
					this.media.push({
						key: s3File.key,
						id: newMedia.data.createMedia.id,
						url
					})
				});
				
				this.newMedia = [];
			}

			this.dataChanged = false;

			this.changesSaved = true;
            setTimeout(() => {
               this.changesSaved = false; 
            }, 2000);
		},
		async initMap() {
			let payload = {...this.company.lat_lng};
			console.log('initMap:', payload, this.company);
			this.$provider.gmap.createMap('company-map', payload, async (res) => {
				// const event = {
				// 	target: {
				// 		name: 'lat_lng',
				// 		value: res
				// 	}
				// }

				// console.log('res:', res);

				// let geoReverseResult = await this.geocodeReverse({ lat: res.lat, lng: res.lng });
				// console.log('geoReverseResult:', geoReverseResult);

				// event.target.value.address = geoReverseResult;
				// this.updateCompanyData(event);

				this.setLocation({ lat: res.lat, lng: res.lng })
			});

			if(!this.company.lat_lng && this.company.location){
				 this.geocodeForward(this.company.location)
			}
		},
		async setLocation(cords, setMapLocation = false) {
			let query = Object.values(cords).join();
			const { data } = await this.$provider.positionStack.getReverse(query);

			if(data.length) {
				const {
					label,
					country,
					city,
					region,
					locality,
					county
				} = data[0];

				this.company.country = data[0].country;
				this.company.city = data[0].locality;
				this.company.province = data[0].region;

				let location={
					...cords,
					address: locality || county || label
				}

				this.company.lat_lng = {
					...this.company.lat_lng,
					address: data[0].label
				}

				this.company.location = location.address;

				this.input = {
					...this.input,
					country: data[0].country,
					city: data[0].locality,
					province: data[0].region,
					location: location.address,
					lat_lng: {
						...this.company.lat_lng,
						address: data[0].label
					}
				};
			}

			if(setMapLocation){
				this.$provider.gmap.setCords('company-map', cords);
				this.showSuggestions = false;
			}
		},
		async geocodeForward(address) {
			const { data } = await this.$provider.positionStack.getForward(address);
			console.log(data);
			if(data.length) {
				this.suggestionsLocations = data;
				this.showSuggestions = true;

				// const { 
				// 	latitude, 
				// 	longitude,
				// } = data[0];

				// console.log('data[0]:', data[0]);
				// this.$provider.gmap.setCords('company-map', { lat: latitude, lng: longitude })
			}
		},
		async geocodeReverse(cords) {
			let query = Object.values(cords).join();
			const { data } = await this.$provider.positionStack.getReverse(query);
			console.log('data:', data);
			if(data.length) {
				const {
					label,
					country,
					city,
					region,
					locationName
				} = data[0];
				return locationName || label
			}
		},
		async getCompanyData() {
			this.company = {
				...this.company,
				...this.companyData
			}

			if(!this.company?.lat_lng?.address){
				this.company.lat_lng = {
					lat: this.company.lat_lng?.lat || 0,
					lng: this.company.lat_lng?.lng || 0,
					address: this.company.lat_lng?.address || ""
				}
				// this.company.lat_lng.address = "";
			}
		},
		async updateCompanyData(ev) {
			
			const target = ev.target;
			const value = target.value;
			const name = target.name;

            this.input = {
				...this.input,
				[name]: value
			};

			if(this.company[name] !== value) this.company[name] = value
			console.log('test',name,value)
			if(name === 'location') this.geocodeForward(value);

            this.dataChanged = true;
		},
		async uploadCompanyPhoto(ev) {
			const files = ev.target.files;
			if(!files.length) return;

			this.newCompanyPhoto = files;
			this.dataChanged = true;
		},
		async uploadMedia(ev) {
			const files = ev.target.files;
			if(!files.length) return;

			this.newMedia = files;
			this.dataChanged = true;
			console.log('this.newMedia:', this.newMedia);
		},
		async uploadDocument(ev) {
			const files = ev.target.files;
			if(!files.length) return;

			this.newDocuments = files;
			this.dataChanged = true;
		},
		async uploadCertificate(ev) {
			const files = ev.target.files;
			if(!files.length) return;

			this.newCertificates = files;
			this.dataChanged = true;
		},
		newCertificatesDelete(itemIndex){
			var newFileList = Array.from(this.newCertificates);
			newFileList.splice(itemIndex, 1);
			this.newCertificates = newFileList;
		},
		newDocumentsDelete(itemIndex){
			var newFileList = Array.from(this.newDocuments);
			newFileList.splice(itemIndex, 1);
			this.newDocuments = newFileList;
		},
		newMediaDelete(itemIndex){
			var newFileList = Array.from(this.newMedia);
			newFileList.splice(itemIndex, 1);
			this.newMedia = newFileList;
		},

		async getAttachments() {
			let requests, requests2, documentRequests, certificatesRequests;

			if(this.companyData && this.companyData.company_photo && this.companyData.company_photo.length) {
				requests2 = this.companyData.company_photo.map(async item => { 
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}	
				});
				Promise.all(requests2).then(responses => this.company_photo = responses);
			}

			if(this.companyData && this.companyData.media && this.companyData.media.items) {
				requests = this.companyData.media.items.filter(item => !item.key.includes('company_photo')).map(async item => {
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}
				});

				Promise.all(requests).then(responses => this.media = responses);
			}
			
			if(this.companyData && this.companyData.documentation && this.companyData.documentation.items) {
				documentRequests = this.companyData.documentation.items.map(async item => {
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key),
						title: item.title 
					}
				})

				Promise.all(documentRequests).then(responses => this.documents = responses);
			}

			if(this.companyData && this.companyData.certifications && this.companyData.certifications.items) {
				certificatesRequests = this.companyData.certifications.items.map(async item => {
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key),
						title: item.title 
					}
				})

				Promise.all(certificatesRequests).then(responses => this.certificates = responses);
			}
		},
		deleteMediaPopupOpen(m, mediaType) {
			this.deleteMediaTarget = m;
			this.deleteMediaType = mediaType;
			this.mediaPopup = true;
		},
		deleteMediaPopupClose() {
			this.deleteMediaTarget = null;
			this.mediaPopup = false;
		},
		async deleteMediaFile() {
			if(!this.deleteMediaTarget) return

			if(this.deleteMediaType === 'company_photo') {
				//deleteMedia
				console.log('this.deleteMediaTarget:', this.deleteMediaTarget);
				await API.graphql({
					query: deleteMedia,
					variables: {
						input: { id: this.companyData.media.items.find(item => item.key === this.deleteMediaTarget.key).id }
					}
				})
				this.company_photo = this.company_photo.filter(m => m.key !== this.deleteMediaTarget.key);

				await API.graphql({
					query: updateCompany,
					variables: { 
						id: this.companyData.id, 
						input: {
							id: this.companyData.id,
							company_photo: []
						}
					}
				})
			}

			if(this.deleteMediaType === 'media') {
				//deleteMedia
				await API.graphql({
					query: deleteMedia,
					variables: {
						input: { id: this.deleteMediaTarget.id }
					}
				})
				this.media = this.media.filter(m => m.key !== this.deleteMediaTarget.key);
			}

			if(this.deleteMediaType === 'document') {
				//deleteDocument
				await API.graphql({
					query: deleteDocument,
					variables: {
						input: { id: this.deleteMediaTarget.id }
					}
				})
				this.documents = this.documents.filter(m => m.key !== this.deleteMediaTarget.key);
			}

			if(this.deleteMediaType === 'certificate') {
				//deleteCertificate
				await API.graphql({
					query: deleteCertificate,
					variables: {
						input: { id: this.deleteMediaTarget.id }
					}
				})
				this.certificates = this.certificates.filter(m => m.key !== this.deleteMediaTarget.key);
			}

			await Storage.remove(this.deleteMediaTarget.key);

			this.deleteMediaPopupClose();

            this.changesSaved = true;
            setTimeout(() => {
               this.changesSaved = false; 
            }, 2000);
		},
		dragLeave(ev) {
			this.dragState = false
		},
		dropCompanyPhoto(ev) {
			const event = { target: ev.dataTransfer }
			this.uploadCompanyPhoto(event);
			this.dragState = false
		},
		dropMedia(ev) {
			const event = { target: ev.dataTransfer }
			this.uploadMedia(event);
			this.dragState = false
		},
		dropDocument(ev) {
			const event = { target: ev.dataTransfer }
			this.uploadDocument(event);
			this.dragState = false;
		},
		dropCertificate(ev) {
			const event = { target: ev.dataTransfer }
			this.uploadCertificate(event);
			this.dragState = false;
		},
		dragOver(ev) {
			this.dragState = true
		},
		closeGallery() {
			this.galleryShow = false;
		},
		openGallery(i) {
			this.currentImage = i;
			this.galleryShow = true;
		},
	},
    async mounted() {
		await this.getCompanyData();
		await this.getAttachments();
		await this.initMap();
        this.input = {
            id: this.companyData.id
        };
	},
}
</script>