<template>
	<PopupWrpr @close="close">
		<div class="popup__window-content">
			<div class="popup__window-header">
				<h2>{{ $translate('add-card') }}</h2>
				<button type="button" class="popup__closer" @click="close">
					<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
				</button>
			</div>
			<div class="popup__window-body">
				<div class="tab-content" id="card-element">
                    <div class="tab-content__item active" id="credit-card">
                        <div class="items-group cards">
                            <div class="cards__col-12">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('card-number') }}</label>
                                    <label class="card-input">
                                        <div class="input-image">
                                            <img ref="card-icon" class="card-icon" src="img/unknown-icon.png">
                                        </div>
                                        <div class="input-el" id="card-number">
                                            <input type="text" placeholder="•••• •••• •••• 1234" class="input" :value="formattedCardNumber" @input="setCardNumber" maxlength="19">
                                        </div>
                                    </label>
                                    <div v-if="errors.numberError" style="color: #ff0000;font-size: 12px; margin-top: -16px;">{{ errors.numberError }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="items-group cards">
                            <div class="cards__col-6">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('cvv-number') }}</label>
                                    <!-- <div id="card-cvv"></div> -->
                                    <input type="text" placeholder="•••" class="input" maxlength="3" v-model="cardData.cvv">
                                </div>
                                <div v-if="errors.cvvError" style="color: #ff0000;font-size: 12px; margin-top: -16px;">{{ errors.cvvError }}</div>
                            </div>
                            <div class="cards__col-6">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('exp.-date') }}</label>
                                    <!-- <div id="card-date"></div> -->
                                    <input type="text" placeholder="02 / 24" maxlength="7" class="input" :class="{ 'input--invalid': dateInvalid }" :value="formattedCardDate" @input="setCardDate">
                                    <div v-if="errors.dateError" style="color: #ff0000;font-size: 12px; margin-top: -3px;">{{ errors.dateError }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="items-group mt-15" v-if="errors.submitError">
                            <span style="color: #ff0000;">{{ errors.submitError }}</span>
                        </div>
                        <!-- <div class="items-group cards" v-if="!cardInfo">
                            <div class="cards__col-12">
                                <div class="el-form save-as mb-0">
                                    <label class="module__check">
                                        <input type="checkbox" name="privacy_policy">
                                        <span class="check"></span>
                                        <span class="text user-select-none">Save as Payment Method</span>
                                    </label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
			</div>
			<div class="popup__window-actions">
				<button type="submit" class="btn btn-primary btn-standart" :class="{ 'disabled': submitDisabled }" @click="createCardPaymentMethod">{{ $translate('confirm') }}</button>
			</div>
			<!-- <div class="popup__window-actions">
				<button type="submit" class="btn btn-primary btn-standart" @click="createPaymentMethod">Create PAyment Confirm</button>
			</div> -->
		</div>
	</PopupWrpr>
</template>

<script>
import PopupWrpr from './PopupWrpr';
import { API } from 'aws-amplify';

export default {
	name: 'CardActions',
	components: {
		PopupWrpr,
	},
    filters: {
        formatCardNumber(x) {
            x = x.toString();

            const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
            const onlyNumbers = x.replace(/[^\d]/g, '')

            return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
                [$1, $2, $3, $4].filter(group => !!group).join(' ')
            );
        },
        formatCardDate(x) {
            x = x.toString();

            const regex = /^(\d{0,2})(\d{0,2})$/g
            const onlyNumbers = x.replace(/[^\d]/g, '')

            return onlyNumbers.replace(regex, (regex, $1, $2) =>
                [$1, $2].filter(group => !!group).join(' / ')
            )
        }
    },
	data() {
		return {
            formattedCardNumber: '',
            formattedCardDate: '',
            numberEl: false,
            dateEl: false,
            cvvEl: false,
            cardIcons: {
                visa: 'img/visa-icon.svg',
                mastercard: 'img/mastercard-icon.svg',
                amex: 'img/amex-icon.svg',
                discover: 'img/discover-icon.svg',
                diners: 'img/diners-icon.svg',
                jcb: 'img/jcb-icon.svg',
                unknown: 'img/unknown-icon.png'
            },
            errors: {
                numberError: false,
                dateError: false,
                cvvError: false,
                submitError: false
            },
            cardData: {
                number: null,
                cvv: '',
                month: null,
                year: null,
            },

            cardNumber: null,
			cardCvc: null,
			cardExpiry: null,

			setupIntent: null,
		}
	},
	computed: {
        submitDisabled() {
            return Object.values(this.errors).some(el => el);
        },
        dateInvalid() {
            const [month, year] = this.formattedCardDate.split(' / ');

            if (!month || month.length < 2) return false;

            if (parseInt(month) > 12) return true;

            if (!year || year.length < 2) return false;

            if (parseInt(year) < (new Date()).getFullYear().toString().substr(2, 4)) return true;

            let [_, currentMonth, currentYear] = (new Date()).toLocaleDateString().split('.');
            currentYear = currentYear.substr(2, 4);

            if (currentYear == year && parseInt(currentMonth) > parseInt(month)) return true;

            return false;
        },
	},
	methods: {
        close() {
            this.$emit('close');
        },
        setCardNumber(event) {
			let val = event.target.value,
				formatted = this.$options.filters.formatCardNumber(val.replace(/\D/g, '')),
				selectionEnd = event.target.selectionEnd + (formatted.slice(0, formatted.length - 1).endsWith(' ') ? 1 : 0);

			this.cardData.number = val.replace(/\D/g, '');
            this.formattedCardNumber = formatted;

			event.target.value = formatted;
			event.target.focus();
			event.target.selectionEnd = selectionEnd;
		},
        setCardDate(event) {
            let val = event.target.value,
				formatted = this.$options.filters.formatCardDate(val.replace(/\D/g, '')),
				selectionEnd = event.target.selectionEnd + 
                    (formatted.slice(0, formatted.length - 1).endsWith(' ') && event.target.selectionEnd == val.replace(/\D/g, '').length ? 3 : 0);

			this.cardData.month = val.replace(/\D/g, '').substr(0, 2);
			this.cardData.year = val.replace(/\D/g, '').substr(2, 4);
            this.formattedCardDate = formatted;

			event.target.value = formatted;
			event.target.focus();
			event.target.selectionStart = selectionEnd;
			event.target.selectionEnd = selectionEnd;
        },
        // confirm() {
        //     if (this.cardInfo) {
        //         this.$emit('save', this.cardData);
        //     } else {
        //         this.$emit('add', this.cardData);
        //     }

        //     this.$emit('close')
        // },
        updatePaymentMethod(id) {},
        setCardIcon(brand) {
            this.$refs['card-icon'].src = (!brand || !this.cardIcons[brand]) ? this.cardIcons.unknown : this.cardIcons[brand];
        },
        // initCardForm() {
        //     const elements = this.$store.state.stripe.elements();

        //     this.numberEl = elements.create('cardNumber', { classes: { base: 'input' }});
        //     this.numberEl.mount("#card-number");
        //     this.numberEl.on("change", (event) => {
        //         console.log('EVENT', event)
        //         console.log('ELEMENT', this.numberEl.emit())

        //         if (event.error && event.error.message) {
        //             this.$set(this.errors, 'numberError', event.error.message);
        //         } else {
        //             this.$set(this.errors, 'numberError', false);
        //         }

        //         this.setCardIcon(event.brand || 'unknown');

        //         this.$set(this.errors, 'submitError', false);
        //     });

        //     this.dateEl = elements.create('cardExpiry', { classes: { base: 'input' }});
        //     this.dateEl.mount("#card-date");
        //     this.dateEl.on("change", (event) => {
        //         if (event.error && event.error.message) {
        //             this.$set(this.errors, 'dateError', event.error.message);
        //         } else {
        //             this.$set(this.errors, 'dateError', false);
        //         }

        //         this.$set(this.errors, 'submitError', false);
        //     });

        //     this.cvvEl = elements.create('cardCvc', { classes: { base: 'input' }});
        //     this.cvvEl.mount("#card-cvv");
        //     this.cvvEl.on("change", (event) => {
        //         if (event.error && event.error.message) {
        //             this.$set(this.errors, 'cvvError', event.error.message);
        //         } else {
        //             this.$set(this.errors, 'cvvError', false);
        //         }

        //         this.$set(this.errors, 'submitError', false);
        //     });
        // },
        async createCardPaymentMethod() {
            const $this = this;

            await this.createPaymentMethod();

            $this.$emit('updateList');
            $this.$emit('close');
        },
        async createPaymentMethod(){
            const response = await API.post('stripeapi', '/stripe/create-payment-method', {
                body: {
                    options: {
                        type: 'card',
                        card: {
                            number: this.cardData.number+'',
                            exp_month: +this.cardData.month,
                            exp_year: +this.cardData.year,
                            cvc: this.cardData.cvv+'',
                        },
                    }
                }
            });

            await API.post('stripeapi', `/stripe/confirm-card-setup`, {
                body: {
                    paymentMethod: response.id,
                    customerID: this.$store.state.userData['stripe_customer_id']
                }
            });

            await API.post('stripeapi', '/stripe/payment-methods-attach', {
                body: {
                    cardID: response.id,
                    userID: this.$store.state.userData['stripe_customer_id'],
                }
            });

            this.$emit('updateList');
            this.$emit('close');
        }
	},
    async mounted() {
        // this.initCardForm();

        this.setupIntent = await API.post('stripeapi', '/stripe/create-setup-intents', {
            body: {
                cardID: null,
                userID: this.$store.state.userData['stripe_customer_id'],
            }
        });
    },
}
</script>
<style scoped>
.mb-0 {
    margin-bottom: 0 !important;
}

.user-select-none {
    user-select: none;
}

.input--invalid {
    border-color: #FF0000;
}

.card-input .input {
    border: 0;
}

.card-icon {
    max-width: 40px;
    max-height: 24px;
    width: 100%;
    height: auto;
    object-fit: contain;
    vertical-align: middle;
}
</style>