<template>
	<div class="wrapper_main footer-fixed">
		<main class="content" @dragleave.self="dragLeave">
			<div class="content__header align-end-md border-none flex-xs-col">
				<div class="content__header-left  flex-md-col no-wrap flex-fill">
					<h1 class="heading-page-h1">{{ $translate('settings') }}</h1>
					
					<div data-target="user-managment" class="hidden-tab" :class="{ 'hidden':activeTab !== 'user-managment' }">
						<div class="content__header-search">
							<input type="search" class="input bg-left search-icon" placeholder="Search" v-model="usersSearch">
						</div>
					</div>
				</div>
				<!-- hidden -->
				<div data-target="user-managment" class="hidden-tab" :class="{ 'hidden':activeTab !== 'user-managment' }">
					<div class="none-checked-actions">
						<div class="content__header-right">
							<button type="button" class="btn btn-w-shadow btn-standart-2" @click="selectAllUsers">
								<span>{{ $translate('select-all') }}</span>
							</button>
							<button type="button" class="btn btn-primary btn-standart">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M12 8V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									<path d="M16 12H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									<path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
								<span>{{ $translate('add-user') }}</span>
							</button>
						</div>
					</div>
					<div class="checked-actions hidden">
						<div class="content__header-right flex-wrap-sm">
							<button class="btn btn-w-shadow btn-standart-2 red-color" data-target="delete-user-action">{{ $translate('delete') }}</button>
							<div class="categories dropdown-wrapper">
								<button class="btn btn-standart dropdown-btn none-shadow">
									<span class="active-categories">{{ $translate('admin') }}</span>
									<span>
										<img src="@/assets/img/Arrow-down.svg">
									</span>
								</button>
								<div class="categories__dropdown dropdown-content">
									<ul>
										<li><span>{{ $translate('admin') }}</span></li>
										<li><span>{{ $translate('user') }}</span></li>
										<li><span>{{ $translate('moderator') }}</span></li>
									</ul>
								</div>
							</div>
							<button type="button" class="btn btn-w-shadow btn-standart-2">
								<span>{{ $translate('select-all') }}</span>
							</button>
							<button type="button" class="btn btn-w-shadow btn-standart-2">
								<span>{{ $translate('cancel') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="tabs-wrapper">
				<ul class="tabs">
					<li class="tabs__item" v-for="tabItem in tabs" :key="tabItem.title">
						<a class="nav-tab" @click="switchTab(tabItem.target)" :class="{ active: activeTab === tabItem.target }">
							{{ tabItem.title }}
						</a>
					</li>
				</ul>
			</div>
			<div class="tab-content" @dragenter.prevent.stop="dragOver"  @drop.prevent @dragover.prevent>
				<div id="account-settings" class="tab-content__item" :class="{ active: activeTab === 'account-settings' }">
					<AccountSettings v-if="activeTab === 'account-settings'" />
				</div>
				<div id="company-info" class="tab-content__item" :class="{ active: activeTab === 'company-info' }">
					<CompanyInfo v-if="activeTab === 'company-info'" />
				</div>
				<div id="user-managment" class="tab-content__item user-managment" :class="{ active: activeTab === 'user-managment' }">
					<TableAdvanced 
						v-model="sortCol" :rows="filteredRows" :columns="columns" 
						:isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" 
						@toggleAllCheckedInputs="toggleAllCheckedInputs"
					>
						<template v-slot:row="slotData">
							<td>
								<label class="module__check">
									<input type="checkbox" name="category" v-model="checkedInputs[slotData.r.id]" />
									<span class="check"></span>
									<span class="text">{{ slotData.r.fullname }}</span>
								</label>
							</td>
							<td>
								<span class="text">{{ slotData.r.email }}</span>
							</td>
							<td>
								<span class="text">{{ slotData.r.role + ' ' + slotData.r.grants }}</span>
							</td>
							<td class="text-end border-action">
								<div class="table-actions flex items-center justify-end">
									<button type="button" class="btn btn-transp">
										<!-- <img src="@/assets/img/ico-gear.svg" alt=""> -->
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M12.1213 7.87868C13.2929 9.05025 13.2929 10.9497 12.1213 12.1213C10.9497 13.2929 9.05025 13.2929 7.87868 12.1213C6.70711 10.9497 6.70711 9.05025 7.87868 7.87868C9.05025 6.70711 10.9497 6.70711 12.1213 7.87868" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											<path fill-rule="evenodd" clip-rule="evenodd" d="M14.18 16.725V16.725C14.683 17.228 15.5 17.228 16.003 16.725L16.725 16.003C17.228 15.5 17.228 14.683 16.725 14.18V14.18C16.343 13.798 16.231 13.225 16.441 12.727C16.463 12.674 16.485 12.621 16.506 12.567C16.689 12.101 17.143 11.801 17.643 11.801H17.71C18.422 11.801 18.999 11.224 18.999 10.512V9.491C18.999 8.779 18.422 8.202 17.71 8.202H17.643C17.143 8.202 16.689 7.901 16.506 7.436C16.485 7.382 16.463 7.329 16.441 7.276C16.231 6.778 16.343 6.205 16.725 5.823V5.823C17.228 5.32 17.228 4.503 16.725 4L16.003 3.278C15.5 2.775 14.683 2.775 14.18 3.278V3.278C13.798 3.66 13.225 3.772 12.727 3.562C12.674 3.54 12.621 3.518 12.567 3.497C12.101 3.311 11.8 2.856 11.8 2.356V2.289C11.8 1.577 11.223 1 10.511 1H9.49C8.777 1 8.2 1.577 8.2 2.289V2.356C8.2 2.856 7.899 3.31 7.434 3.493C7.38 3.515 7.327 3.536 7.274 3.559C6.776 3.769 6.203 3.657 5.821 3.275V3.275C5.318 2.772 4.501 2.772 3.998 3.275L3.275 3.997C2.772 4.5 2.772 5.317 3.275 5.82V5.82C3.657 6.202 3.769 6.775 3.559 7.273C3.536 7.327 3.515 7.38 3.494 7.434C3.311 7.899 2.856 8.2 2.356 8.2H2.289C1.577 8.2 1 8.777 1 9.489V10.51C1 11.223 1.577 11.8 2.289 11.8H2.356C2.856 11.8 3.31 12.101 3.493 12.566C3.514 12.62 3.536 12.673 3.558 12.726C3.768 13.224 3.656 13.797 3.274 14.179V14.179C2.771 14.682 2.771 15.499 3.274 16.002L3.996 16.724C4.499 17.227 5.316 17.227 5.819 16.724V16.724C6.201 16.342 6.774 16.23 7.272 16.44C7.325 16.462 7.378 16.484 7.432 16.505C7.898 16.688 8.198 17.142 8.198 17.642V17.709C8.198 18.421 8.775 18.998 9.487 18.998H10.508C11.22 18.998 11.797 18.421 11.797 17.709V17.642C11.797 17.142 12.098 16.688 12.563 16.505C12.617 16.484 12.67 16.462 12.723 16.44C13.224 16.231 13.797 16.343 14.18 16.725V16.725Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
									</button>
									<button type="button" class="btn btn-transp red-color " data-target="delete-user-action">
										<!-- <img src="@/assets/img/trash-delete.svg" alt=""> -->
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5428 21.0038H8.45698C7.28078 21.0038 6.30288 20.0982 6.21267 18.9255L5.24707 6.37268H18.7527L17.7871 18.9255C17.6969 20.0982 16.719 21.0038 15.5428 21.0038V21.0038Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											<path d="M20.0037 6.37264H3.99707" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											<path fill-rule="evenodd" clip-rule="evenodd" d="M9.18602 2.99622H14.8134C15.4349 2.99622 15.9388 3.50011 15.9388 4.12168V6.37262H8.06055V4.12168C8.06055 3.50011 8.56444 2.99622 9.18602 2.99622Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											<path d="M13.9699 10.8745V16.5019" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											<path d="M10.0305 10.8745V16.5019" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
									</button>
								</div>
							</td>
						</template>	
					</TableAdvanced>

					<Pagination v-model="filteredRows" :items="rawSource" />
				</div>
				<div id="plan-and-billing" class="tab-content__item" :class="{ active: activeTab === 'plan-and-billing' }">
					<PlanAndBilling v-if="activeTab === 'plan-and-billing'" />
				</div>
			</div>
		</main>
	</div>	
</template>

<script>
import { API, Storage, Auth } from 'aws-amplify';

import {
	getUser,
	getCompany,
	listUsers
} from '@/graphql/queries';

import Base from '@/components/base';
import SettingsTabs from '@/views/settings/index.js';

import { mixTables,mixDeleteItem } from '@/mixins';

export default {
	name: 'Settings',
	components: {
		...Base,
		...SettingsTabs,
	},
	mixins:[mixTables, mixDeleteItem],
	data() {
		return {
			tabs: [
				{ title: 'Account Settings', target: 'account-settings' },
				{ title: 'Company Info', target: 'company-info' },
				// { title: 'User Management', target: 'user-managment' },
				{ title: 'Plan & Billing', target: 'plan-and-billing' },
			],
			activeTab: 'account-settings',

			usersSearch: '',
			users: [],
			source: 'products',
			columns: [
                { name: "Name", filter: "fullname" },
                { name: "Email", filter: "email" },
                { name: "Role", filter: "role" },
                { name: "Actions", filter: "" },
            ],
		}
	},
	computed: {
		sourceData(){
			return this.users.filter(item => item.fullname.toLowerCase().includes(this.usersSearch.toLowerCase()) || 
				item.email.toLowerCase().includes(this.usersSearch.toLowerCase()));
		},
	},
	async mounted() {
		await this.getUsers();

		if(this.$route?.query?.tab && this.tabs?.map(item => item.target).includes(this.$route?.query?.tab)){
			this.activeTab = this.$route.query.tab;
		}

		// this.filteredUsers.forEach(item => {
		// 	console.log(item.id);
		// 	this.checkedInputs[item.id] = false;
		// });
	},
	methods: {
		selectAllUsers(){
			this.toggleAllCheckedInputs();
		},
		async getUsers(){
			let company=await API.graphql({
				query: getCompany,
				variables: {
					id: this.$store.state.companyData.id,
				},
			}).then((res) => res.data.getCompany); 

			if(company && company.team){
				let requests = company.team.map(async userID=>{
					return await API.graphql({
						query: getUser,
						variables: {
							id: userID,
						},
					}).then((res) => res.data.getUser);
				});
				await Promise.all(requests).then(res=>this.users=res);
			}
		},
		switchTab(tab) {
			this.activeTab = tab;
			this.$router.push({ path: 'settings', query: { tab }})
		},
	}
}
</script>

<style>
	.nav-tab {
		cursor: pointer;
	}
</style>